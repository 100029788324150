import { TABLE } from '/app.config';

const { edit, del } = TABLE.icons;

const table = {
  data() {
    return {
      columns: [
        {
          width: 230,
          prop: 'name',
          label: '类型名称',
          href: true,
          handle: this.view
        },
        {
          width: 230,
          prop: 'code',
          label: '类型代码'
        }
      ],
      operations: {
        handles: [
          {
            icon: edit,
            name: '编辑',
            handle: this.edit
          },
          {
            icon: del,
            name: '删除',
            handle: this.del
          }
        ]
      }
    };
  }
};
export { table };
