<template>
  <div class="personal">
    <list-container
      placeholder="类型名称/类型代码"
      :customReferenceCountKey="'templateNumber'"
      :searchText.sync="searchText"
      :multipleSelection="multipleSelection"
      :pagination="pagination"
      @add="add"
      @multipleDel="multipleDel"
      @fetch="loadData"
    >
      <template v-slot:grid>
        <grid :Gridtype="5" :GridData="data" :isAdd="true" isUpdate isDel @add="add" @intoGrid="view" @editRow="edit" @deleteRow="del" />
      </template>
      <template v-slot:table>
        <base-table :data="data" :columns="columns" :operations="operations" :multipleSelection.sync="multipleSelection" />
      </template>
    </list-container>
  </div>
</template>

<script>
import listContainer from '@/components/list-container/list-container';
import grid from '@/components/grid/index.vue';
import baseTable from '@/components/table/base-table/base-table';
import { TABLE } from '/app.config';
import { table } from './mixin';
export default {
  name: 'devcietype',
  mixins: [table],
  data() {
    return {
      data: [],
      searchText: '',
      multipleSelection: [],
      pagination: {
        total: 0,
        pageSize: TABLE.page_sizes[0],
        currentPage: 1
      }
    };
  },
  activated() {
    this.loadData();
  },
  methods: {
    async loadData(param = {}) {
      const params = {
        keyword: this.searchText,
        currentPage: param.currentPage || this.pagination.currentPage,
        currentPageSize: param.currentPageSize || this.pagination.pageSize
      };
      const { items, total } = await this.$apis.deviceType.list(params);

      this.data = items;
      this.pagination.total = total;
    },
    // 新增
    add() {
      this.$router.push({ name: 'factoryconfig_devcietype_add' });
    },
    // 删除
    del({ deviceTypeId }) {
      this.$confirm('确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(async () => {
        const { fail } = await this.$apis.deviceType.del({ deviceTypeId });
        if (!fail) {
          this.loadData();
        }
      });
    },
    // 编辑
    edit({ deviceTypeId, name, code, iconUrl }) {
      this.$router.push({
        name: 'factoryconfig_devcietype_edit',
        params: { deviceTypeId, name, code, iconUrl }
      });
    },
    // 详情
    view({ deviceTypeId }) {
      this.$router.push({ name: 'factoryconfig_devcietype_detail', params: { deviceTypeId } });
    },
    multipleDel() {
      const ids = this.multipleSelection.map(item => item.deviceTypeId);
      this.del({ deviceTypeId: ids.join(',') });
    }
  },
  watch: {
    searchText() {
      this.loadData();
    }
  },
  components: {
    listContainer,
    grid,
    baseTable
  }
};
</script>

<style lang="scss" scoped></style>
